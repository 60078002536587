.Login {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Login__image {
  height: 100%;
  width: 50%;
}

.Login__image img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.Login__auth-container {
  height: 100%;
  width: 50%;
  position: relative;
  background-color: white;
}

.authenticator {
  width: 50%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.authenticator__container {
  background-color: var(
    --amplify-components-authenticator-router-background-color
  );
}

.authenticator__form {
  padding: var(--amplify-components-authenticator-form-padding);
}

.authenticator__form-title {
  text-align: center;
  font-weight: bold;
  font-size: clamp(1.5rem, 3.5vh + 0.5rem, 3rem);
}

.authenticator__form-subtitle {
  text-align: center;
  font-size: clamp(14px, 2vh, 22px);
  margin-bottom: 40px;
  color: var(--LT-GRAY);
}

.Login__1904-img {
  width: 100%;
  object-fit: cover;
  position: absolute;
  bottom: 20%;
}

.Login__1904-img img {
  margin: 0 auto;
  width: 60%;
}
