.MiniChartWidget {
  height: 150px;
  width: 400px;
}

.MiniChartWidget__container {
  height: 100%;
}

.MiniChartWidget__grid {
  display: grid;
  align-items: normal;
  height: 100%;
}

.MiniChartWidget__progress-bar {
  color: #62a0a5;
}

.MiniChartWidget__mini-chart-container {
  margin-top: 20px;
}

.MiniChartWidget__mini-chart-container svg {
  overflow: visible;
}
