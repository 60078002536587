@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}
body {
  margin: 0;
  font-family: 'Source Sans Pro', Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* || VARIABLES */
:root {
  /* font */
  --FF: 'Source Sans Pro', Tahoma, Verdana, sans-serif;

  /* color */
  --BGCOLOR: #f1f1f3;
  --TEAL: #62a0a5;
  --LT-TEAL: rgba(98, 160, 165, 0.2);
  --DK-GRAY: #333e48;
  --MED-GRAY: #757575;
  --LT-GRAY: #949494;
  /* general */
  --SHADOWS: 0 2px 6px var(--MED-GRAY);
}
/* Character Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--BGCOLOR);
  font-family: var(--FF);
  position: relative;
  min-height: 100vh;
}
header {
  height: 70px;
  background-color: var(--TEAL);
  width: 100%;
  top: 0;
  position: sticky;
}
.filter {
  max-width: 100%;
  min-height: 70px;
  margin-inline: auto;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  align-items: space-evenly;
  margin: 0 25px 0 25px;
}
.container-kpi {
  max-width: 100%;
  min-height: 100px;
  margin-inline: auto;
  display: flex;
  gap: 1rem;
  /*     justify-content: left; */
  /*     align-items: center; */
  flex-flow: row wrap;
  align-items: space-evenly;
  margin: 0 25px 0 25px;
}
.kpi {
  /*  min-width: 300px; */
  min-height: 150px;
  background-color: white;
  padding: 0.5rem;
  box-shadow: var(--SHADOWS);
  font-size: 1rem;
  color: var(--DK-GRAY);
  text-align: left;
  display: flex;
  /* justify-content: center; */
  align-items: left;
  flex: 1 1 250px;
}
.container {
  max-width: 100%;
  min-height: 400px;
  margin-inline: auto;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
  align-items: space-evenly;
  margin: 0 25px 0 25px;
}
.box {
  /*  min-width: 300px; */
  min-height: 400px;
  background-color: white;
  padding: 0.5rem;
  box-shadow: var(--SHADOWS);
  color: var(--DK-GRAY);
  font-size: 1rem;
  display: flex;
  text-align: left;
  align-items: left;
  flex: 1 1 250px;
}
.table {
  /*  min-width: 300px; */
  max-height: 400px;
  background-color: white;
  padding: 0.5rem;
  box-shadow: var(--SHADOWS);
  margin: 0 25px 0 25px;
  color: var(--DK-GRAY);
  font-size: 1rem;
  display: grid;
  text-align: left;
  align-items: flex-start;
  /*      flex: 1 1 250px; */
}
#calls {
  border-collapse: collapse;
  width: 100%;
}
#calls td,
#calls th {
  padding: 8px;
  font-size: 0.8125rem;
}
#calls th {
  padding-top: 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--LT-GRAY);
  text-align: left;
}
.footer {
  height: 20px;
  background-color: var(--TEAL);
  width: 100%;
  margin-top: 50px;
  bottom: 0;
  position: fixed;
}
/* text styles */
h1 {
  font-size: 1.6rem;
  font-weight: normal;
  padding: 1.5rem;
  text-align: left;
  color: var(--MED-GRAY);
}
h1-2 {
  font-size: 1.6rem;
  padding: 0.5rem;
  color: var(--TEAL);
}
.kpi-header {
  font-size: 1rem;
  text-align: left;
  color: var(--DK-GRAY);
}
.kpi-header-med-gray {
  font-size: 1rem;
  color: var(--LT-GRAY);
}
.header-and-kpi-numbers {
  font-weight: bold;
  font-size: 1.5625rem;
  color: var(--DK-GRAY);
}
.table-copy {
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: var(--DK-GRAY);
}
.placeholder-regular {
  font-size: 0.6875rem;
  line-height: 13px;
  color: var(--MED-GRAY);
}
.legends {
  font-size: 0.8125rem;
  color: var(--MED-GRAY);
}
.button-reversed-lg {
  font-size: 1.125rem;
  color: #fff;
}
.chart-labels {
  font-size: 0.6875rem;
  line-height: 0.9375rem;
  color: var(--LT-GRAY);
}
.table-header {
  font-weight: bold;
  font-size: 0.8125rem;
  color: var(--DK-GRAY);
}
.card-header {
  font-size: 1.125rem;
  color: var(--DK-GRAY);
}
.input-reversed {
  font-size: 0.8125rem;
  color: #fff;
}
.field-label {
  font-size: 0.8125rem;
  color: var(--LT-GRAY);
}
.legend-secondary-color {
  font-size: 0.8125rem;
  color: var(--TEAL);
}
.field-input {
  font-size: 0.8125rem;
  color: var(--MED-GRAY);
}
.button-reversed-small {
  font-size: 1rem;
  line-height: 1.3125rem;
  color: #fff;
}
.placeholder-bold-reversed {
  font-weight: bold;
  font-size: 0.6875rem;
  line-height: 0.8125rem;
  color: #fff;
}
.placeholder-bold {
  font-weight: bold;
  font-size: 0.6875rem;
  line-height: 0.8125rem;
  color: var(--DK-GRAY);
}

.p-3 {
  padding: 15px;
}
