.Dashboard__datepicker {
  margin-left: 25%;
  margin-top: 20px;
  width: 50%;
}

.Dashboard__charts {
  display: flex-root;
  margin: 20px;
}

.Dashboard__row {
  display: flex;
  margin: 20px 0;
  gap: 10px;
  justify-content: space-between;
}

.Dashboard__card {
  width: 100%;
  height: 450px;
}
