.callTranscriptionComponent {
  background-color: #dddddd80;
}

.callTranscriptionComponent th {
  font-weight: bold;
}

.row__heading[data-row-open='true'] {
  background-color: var(--LT-TEAL);
}

td[rowSpan='2'] {
  background-color: var(--TEAL);
}

td[rowSpan='2'] button {
  color: var(--BGCOLOR);
}

.row__subheading th {
  font-weight: bold;
  border-bottom: none;
}

.row__collapsible-section[data-row-open='true'] {
  border-bottom: 2px solid var(--TEAL);
}

.row__header-title {
  border-bottom: none !important;
}

.callTranscription__app-bar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.callTranscription__app-bar-title {
  font-size: clamp(1rem, 1.5vw + 0.5rem, 2rem);
  color: var(--DK-GRAY);
}

.callTranscription__dialog {
  margin-left: auto;
  bottom: -5rem;
  width: 55%;
  height: 75px;
  border: 2px solid var(--TEAL);
  border-radius: 5px;
  padding: 5px;
}

.callTranscription__dialog-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-areas: 'exit inputs';
  grid-template-rows: 1fr;
  grid-template-columns: min-content 1fr;
  justify-content: center;
  align-items: center;
}

.callTranscription__dialog-exit {
  grid-area: exit;
  width: 50px;
  height: 100%;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.callTranscription__dialog-inputs {
  height: 100%;
  width: 100%;
  grid-area: inputs;
  display: flex;
  justify-content: center;
}

.callTranscription__dialog-inputs .MuiFormControl-root:not(:last-child) {
  width: 33%;
}
